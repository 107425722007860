<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogProgramme"
        max-width="1400px"
        scrollable
        :retain-focus="false"
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form === 'add'" class="headline"
                >Add Programme</span
              >
              <span v-if="type_form === 'update'" class="headline"
                >Edit Programme</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogProgramme = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <label style="font-size: 16px">Language</label>
                  <v-radio-group v-model="language" row class="mb-0 pb-0" dense>
                    <v-radio label="Việt Nam" value="vi"></v-radio>
                    <v-radio label="English" value="en"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <ImageCompress
                    :done="getFiles"
                    style="width: 50%"
                    class="pr-2 pl-0"
                  ></ImageCompress>
                  <v-img
                    v-if="language === 'en' && cover_input.en != null"
                    :src="cover_input.en"
                  ></v-img>
                  <v-img
                    v-if="language === 'vi' && cover_input.vi != null"
                    :src="cover_input.vi"
                  ></v-img>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    label="Name"
                    v-if="language === 'en'"
                    v-model="name_input.en"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Name"
                    v-if="language === 'vi'"
                    v-model="name_input.vi"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    label="Slug"
                    v-model="slug_name_input"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    label="Tag line"
                    v-if="language === 'en'"
                    v-model="tag_line_input.en"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Tag line"
                    v-if="language === 'vi'"
                    v-model="tag_line_input.vi"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    label="Entry Level"
                    required
                    v-model="entry_level_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    label="Target"
                    required
                    v-model="target_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    label="Display Entry Level"
                    required
                    v-model="display_entry_level_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    label="Display Target"
                    required
                    v-model="display_target_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="d-flex pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0 col-4"
                    >For Partners Only:
                  </label>
                  <v-radio-group
                    v-model="for_partners_only"
                    row
                    class="mt-0 p-0 ml-1 col-7"
                  >
                    <v-radio label="Yes" value="yes"></v-radio>
                    <v-radio label="No" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6" class="d-flex pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0 col-4"
                    >Featured:
                  </label>
                  <v-radio-group
                    v-model="is_featured"
                    row
                    class="mt-0 p-0 ml-1 col-7"
                  >
                    <v-radio label="Yes" value="yes"></v-radio>
                    <v-radio label="No" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    type="text"
                    label="Price"
                    :value="formatMoney(price_input)"
                    @input="setMoney"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="d-flex pb-0">
                  <v-radio-group v-model="is_active" row class="mt-2 p-0 ml-1">
                    <v-radio label="Activated" :value="1"></v-radio>
                    <v-radio label="Deactivated" :value="0"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="6" class="d-flex pb-0 pt-0">
                  <label style="font-size: 16px" class="mt-1 p-0"
                    >Locked:
                  </label>
                  <v-switch
                    class="mt-0 pt-0 ml-2"
                    v-model="is_locked"
                    :label="`${is_locked.toString().toUpperCase()}`"
                  ></v-switch>
                </v-col>
                <!--                <v-col cols="" class="d-flex" v-if="is_locked === true">-->
                <!--                  <label style="font-size: 16px" class="mt-2 p-0">Unlock at: </label>-->
                <!--                   <date-picker class="ml-2" v-model="unlock_at_input" value-type="format" format="YYYY-MM-DD"/> -->
                <!--                </v-col>-->
                <v-col cols="6" class="d-flex pb-0">
                  <label style="font-size: 16px; max-width: 180px" class="pt-1"
                    >Complete Duration (thời gian trung bình):</label
                  >
                  <!--                  <date-picker-->
                  <!--                      v-model="completion_duration_input"-->
                  <!--                      type="time" format="HH:mm" value-type="format" placeholder="Giờ:Phút" class="ml-1"-->
                  <!--                  >-->
                  <!--                  </date-picker>-->
                  <v-text-field
                    placeholder="Tháng"
                    dense
                    outlined
                    required
                    v-model="completion_duration_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <label style="font-size: 16px">Main teacher</label>
                  <v-autocomplete
                    v-model="main_teacher_id"
                    :items="teacher_tags"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label=""
                    item-text="name"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <label style="font-size: 16px">Courses</label>
                  <v-autocomplete
                    v-model="course_ids"
                    :disabled="isUpdating"
                    :items="course_tags"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label=""
                    item-text="name.vi"
                    item-value="id"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeCourseTag(data.item)"
                      >
                        {{ data.item.name.vi }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name.vi"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <label style="font-size: 16px">Sub Category</label>
                  <v-autocomplete
                    v-model="sub_category_ids"
                    :items="category_all_sub"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label=""
                    item-text="name"
                    item-value="id"
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <label style="font-size: 16px">Gắn Tag</label>
                  <multiselect
                    v-model="tag_selected"
                    tag-placeholder="Tạo tag này"
                    label="name"
                    track-by="id"
                    :options="tags"
                    :multiple="true"
                    :taggable="true"
                  ></multiselect>
                </v-col>
                <!--                <v-col cols="12">-->
                <!--                  <label style="font-size: 16px">Short Description</label>-->
                <!--                  <b-form-textarea placeholder="Enter text" rows="3" v-if="language === 'en'" v-model="short_description_input.en" ></b-form-textarea>-->
                <!--                  <b-form-textarea placeholder="Enter text" rows="3" v-if="language === 'vi'" v-model="short_description_input.vi" ></b-form-textarea>-->
                <!--                </v-col>-->
                <v-col cols="12">
                  <label style="font-size: 16px">Lý do để mua</label>
                  <v-row>
                    <v-col cols="5" class="p-0">
                      <v-textarea
                        outlined
                        auto-grow
                        dense
                        rows="1"
                        class="ml-4 p-0 mt-4 text-body-1"
                        v-model="reason_input"
                        placeholder="Nhập ..."
                      ></v-textarea>
                    </v-col>
                    <v-btn
                      color="cyan"
                      class="ma-2 mt-4 font-weight-bold"
                      outlined
                      small
                      @click="addItemReason"
                    >
                      <v-icon small left>mdi-check</v-icon>
                      Lưu
                    </v-btn>
                    <v-btn
                      color="#E53935"
                      class="mt-4 font-weight-bold"
                      outlined
                      small
                      @click="
                        () => {
                          this.reason_input = '';
                          this.index_reason = null;
                        }
                      "
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Hủy nhập
                    </v-btn>
                  </v-row>
                  <ul
                    style="background-color: #f3f6f9; border-radius: 5px"
                    class="pt-2 pb-2"
                    v-if="reasons_to_buy.length > 0"
                  >
                    <template v-for="(reason, i) in reasons_to_buy">
                      <li class="d-flex mt-4" :key="i">
                        <v-btn
                          color="#FFAB00"
                          outlined
                          small
                          @click="editItemReason(i)"
                        >
                          <v-icon small>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                        <v-btn
                          color="#D84315"
                          class="ml-1"
                          outlined
                          small
                          @click="deleteItemReason(i)"
                        >
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                        <p class="text-body-1 mb-0 ml-2 mr-2 text-justify">
                          {{ reason }}
                        </p>
                      </li>
                    </template>
                  </ul>
                  <div
                    v-if="reasons_to_buy.length <= 0"
                    style="
                      background-color: #f3f6f9;
                      border-radius: 5px;
                      min-height: 30px;
                      padding-top: 5px;
                    "
                  >
                    <p style="text-align: center">No data</p>
                  </div>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Description</label>
                  <Editor
                    v-if="language === 'en'"
                    v-model="description_input.en"
                  ></Editor>
                  <Editor
                    v-if="language === 'vi'"
                    v-model="description_input.vi"
                  ></Editor>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px"
                    >Quyền lợi khi tham gia khóa học</label
                  >
                  <v-card>
                    <v-tabs
                      v-model="tabBenefit"
                      background-color="#f0f0f0"
                      dark
                    >
                      <v-tab
                        v-for="(item_benefit, iItem) in data_benefit"
                        :key="iItem"
                        style="color: #00e676"
                      >
                        <span class="font-weight-bold">{{
                          item_benefit.title
                        }}</span>
                        <v-icon
                          class="d-block ml-1"
                          color="#424242"
                          @click="editTitleBenefit(iItem)"
                        >
                          mdi-file-document-edit-outline
                        </v-icon>
                        <v-icon
                          class="d-block"
                          color="#424242"
                          @click="deleteTitleBenefit(iItem)"
                          >mdi-close</v-icon
                        >
                      </v-tab>
                      <v-tabs-slider color="#8C9EFF"></v-tabs-slider>
                      <v-icon
                        color="cyan"
                        id="iconAddDataBenefit"
                        @click="openDialogTitleBenefit"
                        >mdi-plus</v-icon
                      >
                    </v-tabs>

                    <v-tabs-items
                      vertical
                      v-model="tabBenefit"
                      v-if="data_benefit.length > 0"
                      style="min-height: 100px"
                    >
                      <v-tab-item
                        v-for="(item_benefit, iItem) in data_benefit"
                        :key="iItem"
                      >
                        <v-card flat>
                          <v-row>
                            <v-col cols="5" class="ml-8 p-0 mt-8">
                              <v-textarea
                                v-model="benefit_input"
                                outlined
                                auto-grow
                                dense
                                rows="1"
                                class="text-body-1"
                              ></v-textarea>
                            </v-col>
                            <v-btn
                              color="cyan"
                              class="ma-2 mt-8 font-weight-bold"
                              outlined
                              small
                              @click="btnAddBenefit(iItem)"
                            >
                              <v-icon small left>mdi-check</v-icon>
                              Lưu
                            </v-btn>
                            <v-btn
                              color="#E53935"
                              class="mt-8 font-weight-bold"
                              outlined
                              small
                              @click="resetAddBenefit"
                            >
                              <v-icon small left>mdi-close</v-icon>
                              Hủy
                            </v-btn>
                          </v-row>
                          <ul>
                            <template
                              v-for="(
                                benefit, iBenefit
                              ) in item_benefit.benefits"
                            >
                              <li class="d-flex mt-4" :key="iBenefit">
                                <v-btn
                                  color="#FFAB00"
                                  outlined
                                  @click="editBenefit(iItem, iBenefit)"
                                  small
                                >
                                  <v-icon small
                                    >mdi-file-document-edit-outline</v-icon
                                  >
                                </v-btn>
                                <v-btn
                                  color="#D84315"
                                  class="ml-1"
                                  outlined
                                  @click="deleteBenefit(iItem, iBenefit)"
                                  small
                                >
                                  <v-icon small>mdi-close</v-icon>
                                </v-btn>
                                <p class="text-body-1 mb-0 ml-2 text-justify">
                                  {{ benefit }}
                                </p>
                              </li>
                            </template>
                          </ul>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                    <v-tabs-items
                      vertical
                      v-model="tabBenefit"
                      v-if="data_benefit.length <= 0"
                      style="min-height: 50px"
                    >
                      <p style="text-align: center; margin-top: 20px">
                        No data
                      </p>
                    </v-tabs-items>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Study Guide</label>
                  <v-file-input
                    small-chips
                    label="Chọn file pdf"
                    @change="onFilePdfChange"
                    accept="pdf/*"
                    style="width: 50%"
                    class="pr-2 pl-0"
                  ></v-file-input>
                  <iframe
                    height="100%"
                    width="100%"
                    :src="study_guide_data"
                    v-if="study_guide_data != null"
                  ></iframe>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditProgramme(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditProgramme(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogProgramme = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="dialogTitleBenefit"
        max-width="800px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form_benefit === 'add'"
                >Thêm tiêu đề quyền lợi</span
              >
              <span class="headline" v-else>Sửa tiêu đề quyền lợi</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogTitleBenefit = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-text-field
                    label="Tiêu đề"
                    v-model="title_benefit_input"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnSaveOrEditDataBenefit"
            >
              Save
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogTitleBenefit = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Editor from "@/components/ckeditor5/Editor";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";

export default {
  name: "ProgrammeForm",
  components: {
    Editor,
    Loading: () => import("vue-loading-overlay"),
    ImageCompress: () => import("@/components/image/V-ImageCompress"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: null,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    teacher_tags: {
      type: Array,
      default: () => [],
    },
    course_tags: {
      type: Array,
      default: () => [],
    },
    programme: {
      type: Object,
      default: () => {},
    },
    category_all_sub: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dialogProgramme: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    dialogProgramme(val) {
      if (val) {
        if (this.type_form === "add") {
          this.defaultDataProgramme();
        } else {
          this.setDataForm();
        }
      }
    },
  },
  data() {
    return {
      is_call_api: false,
      name_input: null,
      description_input: null,
      cover_input: null,
      cover_file: null,
      // short_description_input: null,
      tag_line_input: null,
      idProgramme: null,
      is_active: 0,
      course_ids: [],
      main_teacher_id: null,
      price_input: 0,
      language: "vi",
      for_partners_only: "no",
      is_featured: "no",
      isUpdating: false,
      study_guide_input: "",
      is_locked: false,
      unlock_at_input: null,
      tag_selected: [],
      study_guide_data: null,
      study_guide_file: null,
      entry_level_input: "",
      target_input: "",
      reasons_to_buy: [],
      reason_input: "",
      index_reason: null,
      tabBenefit: 0,
      dialogTitleBenefit: false,
      data_benefit: [],
      type_form_benefit: null,
      indexTitleBenefit: null,
      title_benefit_input: "",
      benefit_input: "",
      index_benefit: null,
      slug_name_input: "",
      sub_category_ids: [],
      display_entry_level_input: "",
      display_target_input: "",
      completion_duration_input: null,
    };
  },
  created() {
    this.defaultDataProgramme();
  },
  methods: {
    setDataForm() {
      this.language = "vi";
      this.course_ids = this.programme.courses.data.map((course) => {
        return course.id;
      });
      this.idProgramme = this.programme.id;
      this.name_input = this.programme.name;
      this.description_input = this.programme.description;
      this.cover_input.en = this.programme.cover.en;
      this.cover_file.en = null;
      this.cover_input.vi = this.programme.cover.vi;
      this.cover_file.vi = null;
      this.price_input = this.formatMoney(this.programme.price);
      // this.short_description_input  = this.programme.short_description;
      this.is_featured = this.programme.is_featured === 1 ? "yes" : "no";
      this.for_partners_only =
        this.programme.for_partners_only === 1 ? "yes" : "no";
      this.is_active = this.programme.is_active;
      this.tag_line_input =
        this.programme.tag_line == null
          ? { vi: "", en: "" }
          : this.programme.tag_line;
      this.study_guide_input =
        this.programme.study_guide == null ? "" : this.programme.study_guide;
      this.is_locked = this.programme.is_locked === 1;
      this.unlock_at_input =
        this.programme.is_locked === 1
          ? this.$options.filters.convertTimestampToDate(
              this.programme.unlocked_at
            )
          : null;
      this.tag_selected = JSON.parse(JSON.stringify(this.programme.tags.data));
      this.study_guide_data =
        this.programme.instruction_file === ""
          ? null
          : this.programme.instruction_file;
      this.study_guide_file = null;
      this.entry_level_input = this.programme.entry_level;
      this.target_input = this.programme.target;
      this.reason_input = "";
      this.slug_name_input = this.programme.slug_name;
      this.data_benefit =
        this.programme.benefits == null || this.programme.benefits.length <= 0
          ? []
          : this.programme.benefits;
      this.reasons_to_buy =
        this.programme.reasons_to_buy == null ||
        this.programme.reasons_to_buy.length <= 0
          ? []
          : this.programme.reasons_to_buy;
      this.main_teacher_id =
        this.programme.main_teacher == null
          ? null
          : this.programme.main_teacher.id;
      this.sub_category_ids = this.programme.subCategories.data.map(
        (e) => e.id
      );
      this.display_entry_level_input = this.programme.display_entry_level;
      this.display_target_input = this.programme.display_target;
      // this.completion_duration_input = (this.programme.completion_duration == null) ? this.programme.completion_duration : this.$options.filters.convertSecondToHM(this.programme.completion_duration);
      this.completion_duration_input =
        this.programme.completion_duration == null
          ? null
          : this.programme.completion_duration;
    },
    setMoney(value) {
      this.price_input = value;
    },
    formatMoney(value) {
      let stringMoney = value.toString();
      stringMoney.replace(".", "");
      let money = parseInt(stringMoney.replace(/\D/g, ""), 10);
      if (Number.isNaN(money)) return 0;
      return money.toLocaleString();
    },
    removeTeacherTag() {
      this.main_teacher_id = null;
    },
    removeCourseTag(item) {
      let index = this.course_ids.indexOf(item.id);
      if (index >= 0) this.course_ids.splice(index, 1);
    },
    defaultDataProgramme() {
      this.language = "vi";
      this.name_input = {
        en: "",
        vi: "",
      };
      this.description_input = {
        vi: "",
        en: "",
      };
      this.tag_line_input = {
        vi: "",
        en: "",
      };
      this.is_active = 0;
      this.price_input = "0";
      this.for_partners_only = "no";
      this.is_featured = "no";
      // this.short_description_input = {
      //   en: "",
      //   vi: "",
      // };
      this.cover_input = {
        en: null,
        vi: null,
      };
      this.cover_file = {
        en: null,
        vi: null,
      };
      this.study_guide_input = "";
      this.is_locked = false;
      this.unlock_at_input = null;
      this.tag_selected = [];
      this.study_guide_data = null;
      this.study_guide_file = null;
      this.course_ids = [];
      this.entry_level_input = "";
      this.target_input = "";
      this.reasons_to_buy = [];
      this.reason_input = "";
      this.data_benefit = [];
      this.slug_name_input = "";
      this.main_teacher_id = null;
      this.sub_category_ids = [];
      this.display_entry_level_input = "";
      this.display_target_input = "";
      this.completion_duration_input = null;
    },
    getFiles(obj) {
      if (this.language === "en") {
        this.cover_input.en = obj.blob;
        this.cover_file.en = obj.file;
      }
      if (this.language === "vi") {
        this.cover_input.vi = obj.blob;
        this.cover_file.vi = obj.file;
      }
    },
    onFilePdfChange(file) {
      let vm = this;
      if (file === undefined || file == null) {
        vm.study_guide_data = null;
        vm.study_guide_file = null;
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        vm.study_guide_data = e.target.result;
        vm.study_guide_file = file;
      };
      reader.readAsDataURL(file);
    },
    validateForm() {
      let flat = true;
      if (this.name_input.vi === "" && this.name_input.en === "") {
        this.$toasted.error("Hãy nhập tiêu đề cho chương trình học !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.is_locked === true) {
        if (this.unlock_at_input == null) {
          this.$toasted.error("Hãy nhập thời gian mở khóa !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      return flat;
    },
    async btnAddOrEditProgramme(type) {
      let validateForm = this.validateForm();
      if (!validateForm) {
        return;
      }
      let vm = this;
      let formData = new FormData();
      formData.append("name", JSON.stringify(this.name_input));
      formData.append("description", JSON.stringify(this.description_input));
      formData.append("tag_line", JSON.stringify(this.tag_line_input));
      formData.append("is_active", this.is_active);
      formData.append("study_guide", this.study_guide_input);
      formData.append("course_ids", this.course_ids);
      let price = this.price_input.replaceAll(".", "");
      price = this.price_input.replaceAll(",", "");
      formData.append("price", price);
      // formData.append('short_description', JSON.stringify(this.short_description_input));
      formData.append("is_featured", this.is_featured === "yes" ? 1 : 0);
      formData.append(
        "for_partners_only",
        this.for_partners_only === "yes" ? 1 : 0
      );
      formData.append("is_locked", this.is_locked === true ? 1 : 0);
      formData.append(
        "tag_ids",
        this.tag_selected.map((e) => {
          return e.id;
        })
      );
      if (this.entry_level_input != null)
        formData.append("entry_level", this.entry_level_input);
      if (this.target_input != null)
        formData.append("target", this.target_input);
      if (this.display_target_input)
        formData.append("display_target", this.display_target_input);
      if (this.display_entry_level_input)
        formData.append("display_entry_level", this.display_entry_level_input);
      if (this.reasons_to_buy.length > 0)
        formData.append("reasons_to_buy", JSON.stringify(this.reasons_to_buy));
      if (this.data_benefit.length > 0)
        formData.append("benefits", JSON.stringify(this.data_benefit));
      if (this.unlock_at_input != null) {
        formData.append("unlocked_at", this.unlock_at_input);
      }
      if (this.main_teacher_id != null) {
        formData.append("main_teacher_id", this.main_teacher_id);
      }
      if (this.cover_file.en != null) {
        formData.append("cover_en", this.cover_file.en);
      }
      if (this.cover_file.vi != null) {
        formData.append("cover_vi", this.cover_file.vi);
      }
      if (this.study_guide_file != null) {
        formData.append("instruction_file", this.study_guide_file);
      }
      if (this.slug_name_input != null && this.slug_name_input !== "")
        formData.append("slug_name", this.slug_name_input);
      formData.append("sub_category_ids", this.sub_category_ids);
      // if (this.completion_duration_input) formData.append('complete_duration', this.$options.filters.convertHMToSecond(this.completion_duration_input));
      if (this.completion_duration_input)
        formData.append("complete_duration", this.completion_duration_input);
      if (type === 1) {
        vm.is_call_api = true;
        await ApiService.post("prep-app/programme", formData)
          .then(function (res) {
            if (res.status === 201) {
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.$emit("resetList");
              vm.dialogProgramme = false;
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        await ApiService.post("prep-app/programme/" + vm.idProgramme, formData)
          .then(function (res) {
            if (res.status === 202) {
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.$emit("resetList");
              vm.dialogProgramme = false;
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    addItemReason() {
      if (this.reason_input === null || this.reason_input === "") {
        this.$toasted.error("Lý do không được bỏ trống !", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.index_reason == null) {
        this.reasons_to_buy.push(this.reason_input);
      } else {
        this.reasons_to_buy[this.index_reason] = this.reason_input;
      }
      this.index_reason = null;
      this.reason_input = "";
    },
    editItemReason(index) {
      this.index_reason = index;
      this.reason_input = this.reasons_to_buy[index];
    },
    deleteItemReason(index) {
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.reasons_to_buy.splice(index, 1);
        }
      });
    },
    openDialogTitleBenefit() {
      this.title_benefit_input = "";
      this.dialogTitleBenefit = true;
      this.type_form_benefit = "add";
    },
    btnSaveOrEditDataBenefit() {
      if (
        this.title_benefit_input === null ||
        this.title_benefit_input === ""
      ) {
        this.$toasted.error("Tiêu đề không được bỏ trống !", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.type_form_benefit === "add") {
        let data = {
          title: this.title_benefit_input,
          benefits: [],
        };
        this.tabBenefit = this.data_benefit.push(data) - 1;
      } else {
        this.data_benefit[this.indexTitleBenefit].title =
          this.title_benefit_input;
      }
      this.dialogTitleBenefit = false;
    },
    editTitleBenefit(index) {
      this.indexTitleBenefit = index;
      let data = this.data_benefit[index];
      this.title_benefit_input = data.title;
      this.type_form_benefit = "update";
      this.dialogTitleBenefit = true;
    },
    deleteTitleBenefit(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.data_benefit.splice(index, 1);
        }
      });
    },
    btnAddBenefit(index) {
      if (this.benefit_input === null || this.benefit_input === "") {
        this.$toasted.error("Nhận xét bỏ trống !", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.index_benefit == null) {
        this.data_benefit[index].benefits.push(this.benefit_input);
      } else {
        this.data_benefit[index].benefits[this.index_benefit] =
          this.benefit_input;
      }
      this.resetAddBenefit();
    },
    resetAddBenefit() {
      this.benefit_input = "";
      this.index_benefit = null;
    },
    editBenefit(indexTitle, indexBenefit) {
      this.index_benefit = indexBenefit;
      this.benefit_input = this.data_benefit[indexTitle].benefits[indexBenefit];
    },
    deleteBenefit(indexTitle, indexBenefit) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.data_benefit[indexTitle].benefits.splice(indexBenefit, 1);
        }
      });
    },
  },
};
</script>

<style scoped>
#iconAddDataBenefit {
  max-width: 200px;
  min-width: 90px;
  text-align: center;
  vertical-align: middle;
  background-color: aliceblue;
}

#iconAddDataBenefit::after {
  width: 0;
  height: 0;
}
</style>
